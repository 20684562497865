<template>
  <div class="w-full pt-5 pb-5">
    <div v-if="hasModuleAccess">
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton
              label="Back"
              @onClick="$router.back()"
              variant="secondary"
            />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-4">
            Organization Appraisal
          </h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>

      <template v-if="isLoading">
        <div class="mt-20 h-full">
          <Loader size="xxs" :loader-image="false" />
        </div>
      </template>

      <template v-else>
        <template>
          <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
            <template>
              <div class="flex justify-between items-center">
                <span
                  class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
                >
                  Appraisal Cycle - {{ agreementData.title }}
                </span>
              </div>
            </template>
            <template>
              <div
                class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
              >
                <div
                  class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                  @click="handleShowDetails"
                >
                  <span
                    class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                  >
                    Performance Details
                  </span>
                  <span
                    class="w-9/12 border-t border-solid border-romanSilver h-0"
                  />
                  <Icon
                    :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                    class-name="text-darkPurple mx-auto self-center"
                    size="xms"
                  />
                </div>
                <div
                  class="flex flex-col gap-2"
                  :class="{ hidden: !showDetails }"
                >
                  <div class="flex p-3 shadow rounded-md gap-2">
                    <Icon
                      size="s"
                      icon-name="doc-cycle"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                      >
                        {{ agreementData.cycle }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{ agreementData.description }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                    >
                      <Icon
                        size="xs"
                        icon-name="calendar"
                        class-name="text-romanSilver self-start pt-1"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                        >
                          Period
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.start),
                              "MMMM dd, yyyy"
                            )
                          }}
                          -
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.end),
                              "MMMM dd, yyyy"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                      v-for="(cycle, index) in agreementCycles"
                      :key="index"
                      :class="
                        cycle.active ? '' : 'opacity-40 cursor-not-allowed'
                      "
                    >
                      <Icon
                        class-name="text-romanSilver self-start pt-1"
                        size="xs"
                        icon-name="reviewicon"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                        >
                          {{ cycle.name }}
                        </span>
                        <span
                          class="font-bold text-sm flex leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                          }}
                          -
                          {{
                            $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy")
                          }}
                          <span v-if="extendedCycle">
                            <span
                              class="ml-4 flex"
                              v-if="extendedCycle.appraisalCycleId === cycle.id"
                            >
                              <p
                                class="text-sm uppercase text-mediumSeaGreen italic"
                                style="margin-top: 0.15rem"
                              >
                                Extended
                              </p>
                              <Icon
                                class="ml-3"
                                icon-name="icon-info"
                                size="xs"
                                style="margin-top: -0.15rem"
                              />
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>

        <template>
          <div class="mx-5 mt-10">
            <Tab
              class="mt-6 mx-3"
              :tabs="tabsItems"
              :active-tab="activeTab"
              @currentTab="activateTab($event)"
              :border="true"
            />
            <div v-if="startAppraisal">
              <template v-if="!finalAppraisal">
                <div class="mt-20 h-full" v-if="loadingAppraisal">
                  <Loader size="xxs" :loader-image="false" />
                </div>

                <div class="w-full flex flex-col" v-else>
                  <ValidationObserver
                    rules="required"
                    v-slot="{ handleSubmit }"
                  >
                    <Card class="p-5">
                      <div
                        class="w-full flex flex-col"
                        v-if="templateKPATypes.length > 0"
                      >
                        <div
                          class="w-full flex py-3 justify-between items-center"
                        >
                          <h6 class="font-bold text-lg leading-normal">
                            {{ activeTab }}
                          </h6>

                          <div
                            class="fit-content flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
                            v-if="appraisalMessage !== ''"
                          >
                            <Icon
                              class-name="text-carrotOrange"
                              size="xs"
                              icon-name="icon-info"
                            />
                            <span class="flex text-sm mx-3">
                              {{ appraisalMessage }}
                            </span>
                          </div>
                        </div>

                        <div class="w-full flex flex-col font-black text-xs">
                          <div
                            class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md"
                          >
                            <div class="w-1/2">
                              <p
                                class="leading-5 tracking-wide uppercase text-romanSilver"
                              >
                                KPA Types
                              </p>
                            </div>
                            <div class="w-1/2 mr-4 flex justify-end gap-8">
                              <p
                                class="leading-5 -mr-1 tracking-wide uppercase text-romanSilver"
                              >
                                Allocated Weights
                              </p>
                              <p
                                class="leading-5 mr-1 tracking-wide uppercase text-romanSilver"
                              >
                                Appraisal Score
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="w-full h-auto flex flex-col" v-if="loaded">
                          <Accordion
                            v-for="(kpaTypes, i) in templateKPATypes"
                            :key="i"
                            class="mt-6"
                            show-divider
                            :is-open="false"
                          >
                            <template v-slot:title>
                              <span class="font-bold">{{ kpaTypes.name }}</span>
                            </template>
                            <template v-slot:block>
                              <div
                                class="flex justify-center items-center gap-2 w-3/7 mr-3"
                              >
                                <div
                                  class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38"
                                >
                                  <span
                                    class="text-darkPurple text-xs font-black"
                                  >
                                    {{ kpaTypes.weight }}%
                                  </span>
                                </div>
                                <div
                                  class="w-32 font-bold bg-ghostWhite py-2 rounded-md uppercase"
                                >
                                  <span
                                    class="text-xs w-full flex justify-center font-bold"
                                  >
                                    {{
                                      !getAverageSummary(
                                        kpaTypes.id,
                                        kpaTypes.name
                                      )
                                        ? "---"
                                        : getAverageSummary(
                                            kpaTypes.id,
                                            kpaTypes.name
                                          )
                                    }}
                                  </span>
                                </div>
                              </div>
                            </template>
                            <div class="w-full">
                              <ScrollContainer height="1000px">
                                <Table
                                  :headers="KPAHeader"
                                  :items="kpaTypes.kpas"
                                  :row-header="true"
                                  :has-footer="true"
                                >
                                  <template v-slot:kpis>
                                    <div
                                      class="label flex"
                                      v-for="(n, rating) in Ratings"
                                      :key="rating"
                                    >
                                      <div
                                        class="mr-2 ml-4 -mt-1 text-base font-semibold w-52"
                                      >
                                        {{ rating }}
                                      </div>
                                    </div>
                                  </template>
                                  <template v-slot:managerRating>
                                    <div class="w-72 flex">
                                      <div
                                        class="mr-2 -mt-1 text-sm font-semibold w-3/4"
                                      >
                                        Appraisal Flag
                                      </div>
                                    </div>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <ValidationObserver rules="required">
                                      <div v-if="item.name" class="py-2">
                                        <div
                                          class="mr-2 ml-4 -mt-1 text-base w-52"
                                        >
                                          {{ item.data.name }}
                                        </div>
                                      </div>
                                      <div v-else-if="item.objective">
                                        <div
                                          class="mr-2 ml-4 -mt-1 text-base w-52"
                                        >
                                          {{ item.data.objective }}
                                        </div>
                                      </div>

                                      <div v-else-if="item.kpis" class="flex">
                                        <div
                                          class="label flex"
                                          v-for="(n, rating) in item.data.kpis"
                                          :key="rating"
                                        >
                                          <div
                                            class="mr-2 ml-4 -mt-1 text-base w-52"
                                          >
                                            {{
                                              item.data.kpis[rating].criteria
                                            }}
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.weight">
                                        <div class="label">
                                          <div
                                            class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola"
                                          >
                                            {{ item.data.weight }}
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.managerRating">
                                        <div class="w-72 flex">
                                          <div
                                            class="mr-2 -mt-1 text-sm font-semibold w-3/4"
                                          >
                                            <select
                                              @change="
                                                changeManagerRating(
                                                  $event,
                                                  item,
                                                  templateKPATypes,
                                                  kpaTypes.id,
                                                  'mid_review'
                                                )
                                              "
                                              @input="setInput(kpaTypes.name)"
                                              class="mr-2 -ml-1 mt-1 input-select"
                                              v-model="item.data.ratingId"
                                              :rules="['required']"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                selected
                                              >
                                                --Rating--
                                              </option>
                                              <option
                                                v-for="option in flagOptions"
                                                :value="option.id"
                                                :key="option.id"
                                              >
                                                {{ option.name }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.outcome">
                                        <div class="label ml-6">
                                          <c-text
                                            placeholder="--Enter Comment--"
                                            label="Comment"
                                            variant="w-full"
                                            class="mr-2 -ml-1 w-72"
                                            :disabled="employee"
                                            v-model="item.data.outcome"
                                            :rules="['required']"
                                          />
                                        </div>
                                      </div>
                                    </ValidationObserver>
                                  </template>
                                  <template v-slot:footer>
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                      <div
                                        class="w-full flex justify-end p-3 -ml-12"
                                      >
                                        <span
                                          class="text-lg font-black text-end"
                                        >
                                          Summary
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="w-full flex">
                                        <div
                                          class="w-3/4 ml-3 justify-end flex p-1 bg-ghostWhite"
                                        >
                                          <span
                                            class="text-lg text-center font-black w-full"
                                            style="border-radius: 5px"
                                          >
                                            {{
                                              !getAverageSummary(
                                                kpaTypes.id,
                                                kpaTypes.name
                                              )
                                                ? "---"
                                                : getAverageSummary(
                                                    kpaTypes.id,
                                                    kpaTypes.name
                                                  )
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </template>
                                </Table>
                              </ScrollContainer>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </Card>
                    <div class="flex flex-col w-full" v-if="loaded">
                      <template>
                        <div
                          class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap"
                        >
                          <span
                            class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider"
                          >
                            Summary
                          </span>
                          <div
                            class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52"
                          />
                          <div
                            class="flex justify-end gap-10 bg-white relative px-5"
                          >
                            <div class="flex flex-col items-start leading-5">
                              <span
                                class="text-gray-400 text-xs text-center font-black uppercase"
                                >Appraisal Flag</span
                              >
                              <span
                                class="font-semibold text-base text-darkPurple"
                                >{{ approvedScore }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="loaded">
                        <div
                          class="w-full flex p-3 gap-4 justify-between items-center"
                          v-if="!appraisalEnd"
                        >
                          <div
                            class="flex justify-start flex-grow gap-2"
                            v-if="appraisalState === 'draft'"
                          >
                            <Button
                              class="bg-dynamicBackBtn text-white"
                              width="8rem"
                              @click="handleSubmit(modalDisplay)"
                              style="height: 3rem"
                              v-if="lineManagerAppraisalScore === ''"
                            >
                              <span class="mr-6 ml-6">Save</span>
                            </Button>

                            <Button
                              background-color="none"
                              class="ml-4"
                              style="color: #321c3b"
                              @click="cancel()"
                            >
                              Cancel
                            </Button>
                          </div>

                          <div
                            class="flex justify-end"
                            v-if="appraisalState === 'draft'"
                          >
                            <Button
                              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
                              width="8rem"
                              @click="submitAppraisal('draft')"
                            >
                              Save Draft
                            </Button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </ValidationObserver>
                </div>
              </template>

              <template v-else>
                <div class="mt-20 h-full" v-if="loadingAppraisal">
                  <Loader size="xxs" :loader-image="false" />
                </div>

                <div class="w-full flex flex-col" v-else>
                  <ValidationObserver
                    rules="required"
                    v-slot="{ handleSubmit }"
                  >
                    <Card class="p-5" v-if="loaded">
                      <div class="w-full flex flex-col">
                        <div
                          class="w-full flex py-3 mt-2 justify-between items-center"
                        >
                          <h6 class="font-bold text-lg leading-normal">
                            {{ activeTab }}
                          </h6>

                          <div
                            class="fit-content flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
                            v-if="appraisalMessage !== ''"
                          >
                            <Icon
                              class-name="text-carrotOrange"
                              size="xs"
                              icon-name="icon-info"
                            />
                            <span class="flex text-sm mx-3">
                              {{ appraisalMessage }}
                            </span>
                          </div>
                        </div>

                        <div
                          class="w-full flex flex-col font-black text-xs mt-1"
                        >
                          <div
                            class="w-full flex px-3 py-1 my-3 justify-between align-center bg-cultured rounded-md"
                          >
                            <div class="w-1/2">
                              <p
                                class="leading-5 tracking-wide uppercase text-romanSilver"
                              >
                                KPA Types
                              </p>
                            </div>
                            <div class="w-1/2 flex justify-end gap-8">
                              <p
                                class="leading-5 tracking-wide mr-2 uppercase text-romanSilver"
                              >
                                Allocated Weight
                              </p>
                              <p
                                class="leading-5 tracking-wide mr-2 uppercase text-romanSilver"
                              >
                                Appraisal Score
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="w-full h-auto flex flex-col" v-if="loaded">
                          <Accordion
                            v-for="(kpaTypes, i) in templateKPATypes"
                            :key="i"
                            class="mt-6"
                            show-divider
                            :is-open="false"
                          >
                            <template v-slot:title>
                              <span class="font-bold">{{ kpaTypes.name }}</span>
                            </template>
                            <template v-slot:block>
                              <div
                                class="flex justify-center items-center gap-2 w-3/7 mr-3"
                              >
                                <div
                                  class="font-bold bg-ghostWhite px-12 py-1 rounded-md w-38"
                                >
                                  <span
                                    class="text-darkPurple text-xs font-black"
                                  >
                                    {{ kpaTypes.weight }}%
                                  </span>
                                </div>
                                <div
                                  class="font-black bg-ghostWhite py-2 w-32 rounded-md uppercase"
                                >
                                  <span
                                    class="text-xs w-full flex justify-center font-black"
                                  >
                                    {{
                                      getKPATotalScore(
                                        kpaTypes.id,
                                        kpaTypes
                                      ) === ""
                                        ? "---"
                                        : getKPATotalScore(
                                            kpaTypes.id,
                                            kpaTypes
                                          )
                                    }}
                                  </span>
                                </div>
                              </div>
                            </template>
                            <div class="w-full">
                              <ScrollContainer height="1000px">
                                <Table
                                  :headers="KPAHeader"
                                  :items="kpaTypes.kpas"
                                  :row-header="true"
                                  :has-footer="true"
                                >
                                  <template v-slot:kpis>
                                    <div
                                      class="label flex"
                                      v-for="(n, rating) in Ratings"
                                      :key="rating"
                                    >
                                      <div
                                        class="mr-2 ml-4 -mt-1 text-base font-semibold w-52"
                                      >
                                        {{ rating }}
                                      </div>
                                    </div>
                                  </template>

                                  <template v-slot:managerRating>
                                    <div class="w-72 flex">
                                      <div
                                        class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                      >
                                        Rating
                                      </div>
                                      <div
                                        class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                      >
                                        Score
                                      </div>
                                    </div>
                                  </template>

                                  <template v-slot:item="{ item }">
                                    <ValidationObserver rules="required">
                                      <div v-if="item.name" class="py-2">
                                        <div
                                          class="mr-2 ml-4 -mt-1 text-base w-52"
                                        >
                                          {{ item.data.name }}
                                        </div>
                                      </div>
                                      <div v-else-if="item.objective">
                                        <div
                                          class="mr-2 ml-4 -mt-1 text-base w-52"
                                        >
                                          {{ item.data.objective }}
                                        </div>
                                      </div>

                                      <div v-else-if="item.kpis" class="flex">
                                        <div
                                          class="label flex"
                                          v-for="(n, rating) in item.data.kpis"
                                          :key="rating"
                                        >
                                          <div
                                            class="mr-2 ml-4 -mt-1 text-base w-52"
                                          >
                                            {{
                                              item.data.kpis[rating].criteria
                                            }}
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.weight">
                                        <div class="label">
                                          <div
                                            class="mr-2 ml-4 -mt-1 w-32 text-base font-black text-blueCrayola"
                                          >
                                            {{ item.data.weight }}
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.managerRating">
                                        <div class="w-72 flex">
                                          <div
                                            class="mr-2 -mt-1 text-sm font-semibold w-1/2"
                                          >
                                            <select
                                              class="mr-2 -ml-1 mt-1 input-select"
                                              @change="
                                                changeManagerRating(
                                                  $event,
                                                  item,
                                                  templateKPATypes,
                                                  kpaTypes.id,
                                                  'final_review'
                                                )
                                              "
                                              v-model="item.data.ratingId"
                                            >
                                              <option
                                                value=""
                                                disabled
                                                selected
                                              >
                                                --Rating--
                                              </option>
                                              <option
                                                v-for="option in ratingOptions"
                                                :value="option.id"
                                                :key="option.id"
                                              >
                                                {{ option.name }}
                                              </option>
                                            </select>
                                          </div>
                                          <div
                                            class="mr-2 label text-sm font-semibold w-1/2"
                                          >
                                            <c-text
                                              placeholder="--Score--"
                                              variant="w-full"
                                              class="mr-2 -ml-1"
                                              type="number"
                                              :disabled="true"
                                              v-model="item.data.managerScore"
                                              :rules="['required']"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div v-else-if="item.outcome">
                                        <div class="label ml-6">
                                          <c-text
                                            placeholder="--Enter Comment--"
                                            variant="w-full"
                                            class="mr-2 -ml-1 w-72"
                                            :disabled="employee"
                                            v-model="item.data.outcome"
                                            :rules="['required']"
                                          />
                                        </div>
                                      </div>
                                    </ValidationObserver>
                                  </template>

                                  <template v-slot:footer>
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                      <div
                                        class="w-full flex justify-end p-3 -ml-4"
                                      >
                                        <span
                                          class="text-lg font-black text-end"
                                        >
                                          Total Scores
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="w-full flex">
                                        <div class="w-1/2" />
                                        <div
                                          class="w-32 justify-end flex p-1 bg-ghostWhite"
                                        >
                                          <span
                                            class="text-lg text-center font-black w-full"
                                            style="border-radius: 5px"
                                          >
                                            {{
                                              getKPATotalScore(
                                                kpaTypes.id,
                                                kpaTypes
                                              ) === ""
                                                ? "---"
                                                : getKPATotalScore(
                                                    kpaTypes.id,
                                                    kpaTypes
                                                  )
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </template>
                                </Table>
                              </ScrollContainer>
                            </div>
                          </Accordion>
                        </div>
                      </div>
                    </Card>

                    <div class="flex flex-col w-full" v-if="loaded">
                      <template>
                        <div
                          class="w-full flex p-5 shadow rounded-md my-2 justify-between items-center relative text-right whitespace-nowrap"
                        >
                          <span
                            class="font-bold text-lg leading-5 text-left text-darkPurple tracking-wider"
                          >
                            Final Score
                          </span>
                          <div
                            class="absolute left-0 right-0 border border-solid border-gray-400 h-0 ml-36 mr-52"
                          />
                          <div
                            class="flex justify-end gap-10 bg-white relative px-5"
                          >
                            <div class="flex flex-col items-start leading-5">
                              <span
                                class="text-gray-400 text-xs text-center font-black uppercase"
                                >Appraisal Score</span
                              >
                              <span
                                class="font-semibold text-base text-darkPurple"
                                >{{ approvedScore }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </template>
                      <template>
                        <div
                          class="w-full flex p-3 gap-4 justify-between items-center"
                          v-if="!appraisalEnd"
                        >
                          <div
                            class="flex flex-grow justify-start gap-2"
                            v-if="appraisalState === 'draft'"
                          >
                            <Button
                              class="bg-dynamicBackBtn text-white"
                              width="8rem"
                              @click="handleSubmit(modalDisplay)"
                              style="height: 3rem"
                            >
                              <span class="mr-6 ml-6">Save</span>
                            </Button>

                            <Button
                              background-color="none"
                              class="ml-4"
                              style="color: #321c3b"
                              @click="$router.back()"
                            >
                              Cancel
                            </Button>
                          </div>

                          <div
                            class="flex justify-end"
                            v-if="appraisalState === 'draft'"
                          >
                            <Button
                              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
                              width="8rem"
                              @click="submitAppraisal('draft')"
                            >
                              Save Draft
                            </Button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </ValidationObserver>
                </div>
              </template>

              <div
                class="w-full"
                v-if="appraisalMessage !== '' && !startAppraisal"
              >
                <div
                  class="w-full flex flex-col justify-center items-center mt-20"
                >
                  <icon
                    icon-name="empty_agreement"
                    size="l"
                    style="width: 300px"
                  />
                  <div class="w-1/2 text-base text-center">
                    {{ appraisalMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <Modal v-if="isSave">
        <Card class="p-5 flex flex-col justify-center max-w-md">
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to Save this Appraisal?
          </p>
          <p class="ml-4 text-center">This appraisal can only be saved once</p>
          <div class="flex flex-row justify-center gap-5 mt-5">
            <Button class="bg-dynamicBackBtn text-white" @click="submitAppraisal">
              Yes
            </Button>

            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="isSave = false"
            >
              No
            </Button>
          </div>
        </Card>
      </Modal>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import ScrollContainer from "@/components/ScrollContainer";
import Loader from "@/components/Loader";
import Accordion from "@/components/Accordion";
import Table from "@/components/Table";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "OrganisationAppraisal",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    Modal,
    Tab,
    Breadcrumb,
    BackButton,
    Button,
    ErrorComponent,
    ScrollContainer,
    CText,
    Loader,
    Card,
    Accordion,
    Table,
    Icon,
  },
  data() {
    return {
      hasModuleAccess: false,
      openModal: false,
      isLoading: true,
      loaded: false,
      loadingAppraisal: true,
      isOpenComments: false,
      loadComments: true,
      inputChange: [],
      isManager: false,
      tabsItems: [],
      activeTab: `${this.$route.params.cycleName}`,
      KPAHeader: [
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "KPI", value: "kpis" },
        { title: "Weight (%)", value: "weight" },
        { title: "Appraisal", value: "managerRating" },
        { title: "Comment", value: "outcome" },
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisals",
          href: "performance-appraisals",
          id: "PerformanceAppraisals",
        },
        {
          disabled: false,
          text: "Employee Appraisal",
          href: "employee-appraisal",
          id: "EmployeeAppraisal",
        },
      ],
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      agreementCycles: [],
      startAppraisal: true,
      appraisalStartDate: "",
      appraisalEndDate: "",
      appraisalMessage: "",
      appraisalEnd: false,
      templateKPATypes: [],
      Ratings: [],
      ratingOptions: [],
      flagOptions: [],
      orgAppraisals: [],
      kpaAppraisalSummary: [],
      selfAppraisalScore: "",
      lineManagerAppraisalScore: "",
      approvedScore: "",
      appraisalStatus: "",
      appraisalFlag: "",
      appraisalState: "",
      appraisalAgreed: "",
      checkEditAppraisal: false,
      disableAppraisal: false,
      appraisalDisplay: "",
      employee: false,
      finalAppraisal: false,
      cyclesArr: [],
      allComments: [],
      comment: "",
      employees: [],
      showDetails: false,
      payload: {
        goalId: "",
        orgId: "",
        appraisal_status: "not_submitted",
        status: "draft",
        cycleId: "",
        appraisalBy: "",
        kpa_types: [],
      },
      extendedCycle: {},
      isSave: false,
    };
  },

  methods: {
    setInput(kpaTypeName) {
      if (!this.inputChange.includes(kpaTypeName)) {
        this.inputChange.push(kpaTypeName);
      }
    },

    modalDisplay() {
      this.isSave = true;
    },

    activateTab(tab) {
      const tabIndex = this.tabsItems.indexOf(tab, 0);
      this.inputChange = [];

      if (tabIndex === this.tabsItems.length - 1) {
        this.finalAppraisal = true;
        this.payload.reviewType = "final_review";
      } else {
        this.finalAppraisal = false;
        this.payload.reviewType = "mid_review";
      }

      this.cyclesArr.filter((cycle) => {
        if (cycle.name === tab) {
          this.payload.cycleId = cycle.id;
        }
        return {};
      });

      this.orgAppraisals = [];
      this.templateKPATypes = [];
      this.loaded = false;
      this.loadingAppraisal = true;
      this.startAppraisal = true;
      this.appraisalMessage = "";
      this.appraisalEnd = false;
      this.approvedScore = "";
      this.appraisalState = "draft";

      this.cyclesArr.filter((cycle) => {
        if (cycle.name === this.activeTab) {
          this.appraisalStartDate = this.setDates(cycle.appraisal_starts);
          this.appraisalEndDate = this.setDates(cycle.appraisal_ends);

          this.checkAppraisalStart();
        }
        return {};
      });

      this.activeTab = tab;
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    changeManagerRating(event, item, kpaTypes, kpaTypeId, reviewType) {
      const ratingId = event.target.value;
      const { kpaId } = item.data;
      let sum = 0;
      let totalSum = 0;
      let totalWeight = 0;

      this.$_calculateManagerRatingScore(kpaId, ratingId, reviewType).then(
        (response) => {
          const itemVariable = item;
          itemVariable.data.managerScore = response.data.data.score;
          kpaTypes.forEach((type) => {
            if (type.id === kpaTypeId) {
              type.kpas.forEach((items) => {
                sum += Number(items.managerScore);
                totalWeight += Number(items.weight);
              });
            }
          });

          kpaTypes.forEach((type) => {
            type.kpas.forEach((items) => {
              totalSum += Number(items.managerScore);
            });
          });

          const weightedScore = (sum / totalWeight) * 100;

          if (reviewType === "mid_review") {
            this.getAverageAppraisal(
              weightedScore.toFixed(2) || 0,
              kpaTypeId,
              item
            );
            this.scoreFlag(item);
          } else {
            this.lineManagerAppraisalScore = totalSum.toFixed(2);
          }
        }
      );
    },

    async getAverageAppraisal(score, id) {
      try {
        const { data } = await this.$_getScoreAppraisalFlag(score);
        const found = this.kpaAppraisalSummary.some((el) => el.id === id);
        if (found) {
          this.kpaAppraisalSummary.map((kpaValue) => {
            if (kpaValue.id === id) {
              const summaryVariable = kpaValue;

              summaryVariable.summary = data.ranking.flag.name;
            }
            return {};
          });
        } else {
          this.kpaAppraisalSummary.push({
            id,
            summary: data.ranking.flag.name,
          });
        }
      } catch (err) {
        throw new Error(err);
      }
    },

    getAverageSummary(id, kpaTypeName) {
      let summary;
      const appraisalSummary = [];

      this.orgAppraisals.forEach((appraisal) => {
        appraisal.summary.forEach((el) => {
          if (el.kpaTypeFlag) {
            appraisalSummary.push(el.kpaTypeName);
          }
        });
      });

      if (
        appraisalSummary.includes(kpaTypeName) &&
        !this.inputChange.includes(kpaTypeName)
      ) {
        summary = this.getKPATotalManagerAppraisal(kpaTypeName);
      } else {
        this.kpaAppraisalSummary.forEach((kpa) => {
          if (kpa.id === id) {
            summary = kpa.summary;
          }
        });
      }
      return summary || "";
    },

    async scoreFlag(item) {
      const varItem = item || null;

      const score = (item.data.managerScore / item.data.weight) * 100;

      try {
        const { data } = await this.$_getScoreAppraisalFlag(score);
        varItem.data.outcome = data.ranking.description.description;
      } catch (err) {
        throw new Error(err);
      }
    },

    getKPATotalScore(kpaId, kpaTypes) {
      let sum = 0;
      let kpaArray = [];

      if (kpaTypes.id === kpaId) {
        kpaArray = kpaTypes.kpas;
      }

      const hasNull = kpaArray.every(
        (e) => e.managerScore === null || e.managerScore === undefined
      );

      if (!hasNull) {
        kpaArray.map((items) => {
          if (items.managerScore) {
            sum += items.managerScore;
          }
          return {};
        });

        if (sum >= 0) {
          return sum.toFixed(2);
        }
      }

      return "";
    },

    getKPATotalManagerAppraisal(kpaTypesname) {
      let appraisalVal;
      this.orgAppraisals.filter((appraisal) => {
        if (appraisal.cycle === this.activeTab) {
          appraisal.summary.filter((summary) => {
            if (summary.kpaTypeName === kpaTypesname) {
              appraisalVal = summary.kpaTypeFlag;
            }
            return {};
          });
        }
        return {};
      });

      return appraisalVal || "---";
    },

    checkAppraisalStart() {
      const today = new Date();

      this.appraisalEndDate.setDate(this.appraisalEndDate.getDate() + 1);

      if (today >= this.appraisalStartDate && today <= this.appraisalEndDate) {
        this.startAppraisal = true;
        this.getOrganizationAppraisal();
      } else if (
        today < this.appraisalStartDate &&
        today <= this.appraisalEndDate
      ) {
        this.appraisalMessage = `${this.activeTab} appraisal starts on the
                          ${this.$DATEFORMAT(
                            new Date(this.appraisalStartDate),
                            "MMMM dd, yyyy"
                          )}. You will be notified when it begins.`;
        this.appraisalEnd = true;
      } else {
        this.startAppraisal = true;
        this.appraisalMessage = `${this.activeTab} appraisal ended on
                          ${this.$DATEFORMAT(
                            new Date(
                              this.appraisalEndDate.setDate(
                                this.appraisalEndDate.getDate() - 1
                              )
                            ),
                            "MMMM dd, yyyy"
                          )}.`;
        this.appraisalEnd = true;
      }
      this.getOrganizationAppraisal();
    },

    getCycleTabs() {
      const today = new Date();
      this.tabsItems = [];

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const { extension } = response.data;

        this.extendedCycle = extension;

        const appraisalCycle = appraisal[0].cycles.sort(
          (a, b) =>
            this.setDates(a.appraisal_starts) -
            this.setDates(b.appraisal_starts)
        );

        appraisalCycle.map((cycle) => {
          this.tabsItems.push(cycle.name);

          const tabIndex = this.tabsItems.indexOf(
            this.$route.params.cycleName,
            0
          );

          const cycleStartDate = new Date(
            this.setDates(cycle.appraisal_starts).toISOString()
          );

          const cycleEndDate = new Date(
            this.setDates(cycle.appraisal_ends).toISOString()
          );

          cycleEndDate.setDate(cycleEndDate.getDate() + 1);

          this.cyclesArr.push({
            id: cycle.id,
            name: cycle.name,
            appraisal_starts: cycle.appraisal_starts,
            appraisal_ends: cycle.appraisal_ends,
          });

          this.agreementCycles.push({
            name: cycle.name,
            start: this.setDates(cycle.appraisal_starts).toISOString(),
            end: this.setDates(cycle.appraisal_ends).toISOString(),
            active: today >= cycleStartDate && today <= cycleEndDate,
            id: cycle.id,
          });

          if (tabIndex === this.tabsItems.length - 1) {
            this.finalAppraisal = true;
            this.payload.reviewType = "final_review";
          } else {
            this.finalAppraisal = false;
            this.payload.reviewType = "mid_review";
          }

          if (cycle.name === this.$route.params.cycleName) {
            this.payload.cycleId = cycle.id;
            this.appraisalStartDate = this.setDates(cycle.appraisal_starts);
            this.appraisalEndDate = this.setDates(cycle.appraisal_ends);

            this.checkAppraisalStart();
          }

          return {};
        });
      });
    },

    async submitAppraisal(status) {
      try {
        await this.$handlePrivilege(
          "performanceAppraisals",
          "saveOrganizationAppraisal"
        );

        const kpaData = [];
        const validateKPA = [];
        this.payload.orgId = this.$orgId;
        this.payload.appraisalBy = this.$AuthUser.id;
        this.payload.year = new Date().getFullYear();

        this.templateKPATypes.forEach((kpaType) => {
          const newKPAs = kpaType.kpas.map(
            ({
              name,
              objective,
              kpis,
              weight,
              selfRating,
              selfScore,
              managerRating,
              managerScore,
              ...rest
            }) => ({
              ...rest,
            })
          );
          const validation = newKPAs.every((item) => item.ratingId);
          validateKPA.push(validation);
          kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        });

        if (!status) {
          this.payload.appraisal_status = "submitted";
          this.payload.status = "active";
          this.isSave = false;

          if (validateKPA.every((valid) => valid === true)) {
            this.payload.kpa_types = kpaData;
            this.$_appraiseOrganization(this.payload)
              .then((response) => {
                this.$toasted.success(response.data.message, {
                  duration: 5000,
                });
                this.$router.back();
              })
              .catch((err) => {
                this.$toasted.error(err.response.data.message, {
                  duration: 5000,
                });
              });
          } else if (validateKPA.every((valid) => valid === false)) {
            if (this.finalAppraisal) {
              this.$toasted.error("No scores set for each KPA", {
                duration: 5000,
              });
            } else {
              this.$toasted.error("No flags set for each KPA", {
                duration: 5000,
              });
            }
          }
        } else {
          this.payload.kpa_types = kpaData;
          this.$_appraiseOrganization(this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.$router.back();
            })
            .catch((err) => {
              this.$toasted.error(err.response.data.message, {
                duration: 5000,
              });
            });
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    getOrganizationAppraisal() {
      this.orgAppraisals = [];

      this.$_getOrganizationAppraisal(
        `?reviewType=${this.payload.reviewType}`
      ).then((result) => {
        const responseData = result.data.appraisals;

        responseData.forEach((response) => {
          if (response.appraisal.current_cycle) {
            if (this.finalAppraisal) {
              if (response.appraisal.current_cycle.name === this.activeTab) {
                this.approvedScore = response.appraisal.approved_score;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;

                response.appraisal.performance_scores.forEach((score) => {
                  this.orgAppraisals.push({
                    cycle: response.appraisal.current_cycle.name,
                    id: score.kpaId,
                    managerRatingPoint: score.rating,
                    flagId: score.ranking.flag.name,
                    managerScore: score.score,
                    outcome: score.outcome,
                    summary: response.summary,
                  });
                });
              } else {
                delete this.payload.appraisalId;
              }
            }

            if (!this.finalAppraisal) {
              if (response.appraisal.current_cycle.name === this.activeTab) {
                this.approvedScore = response.appraisalSummary.appraisalFlag;
                this.appraisalStatus = response.appraisal.appraisal_status;
                this.appraisalState = response.appraisal.appraisal_state;
                this.payload.appraisalId = response.appraisal.id;

                response.appraisal.performance_scores.forEach((score) => {
                  this.orgAppraisals.push({
                    cycle: response.appraisal.current_cycle.name,
                    id: score.kpaId,
                    managerRatingPoint: score.rating,
                    flagId: score.ranking.flag.name,
                    managerScore: score.score,
                    outcome: score.outcome,
                    summary: response.summary,
                  });
                });
              } else {
                delete this.payload.appraisalId;
              }
            }
          }
        });
      });

      setTimeout(() => {
        this.getAgreements();
      }, 2000);
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      this.Ratings = sortedRatings;
      this.Ratings.map((rating, index) => {
        criteriaData.push({
          performanceRatingId: rating.id,
          criteria: kpis[index].criteria,
        });
        return {};
      });

      this.loaded = true;
      this.loadingAppraisal = false;

      return criteriaData;
    },

    getAgreements() {
      this.templateKPATypes = [];
      this.$_getOrgPerformanceAgreement()
        .then((response) => {
          const result = response.data.data;
          let agreementKpa = [];

          result.forEach((info) => {
            if (info.agreementDetail.year === Number(this.$route.params.year)) {
              this.payload.goalId = info.agreementDetail.id;

              this.agreementData.title =
                info.agreementDetail.goal_performance_templates.agreement_title;
              this.agreementData.cycle =
                info.agreementDetail.goal_performance_templates.agreement_title;
              this.agreementData.period.start = this.setDates(
                info.agreementDetail.goal_performance_templates.appraisal_cycle
                  .start
              ).toISOString();
              this.agreementData.period.end = this.setDates(
                info.agreementDetail.goal_performance_templates.appraisal_cycle
                  .end
              ).toISOString();

              agreementKpa =
                info.agreementDetail.goal_performance_templates.kpa_types;

              if (this.templateKPATypes.length === 0) {
                agreementKpa.forEach((kpaType) => {
                  this.templateKPATypes.push({
                    id: kpaType.id,
                    name: kpaType.name,
                    weight: kpaType.weight,
                    kpas: this.fillKPAS(kpaType.kpas),
                  });
                });
              }
            }
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    fillKPAS(kpas) {
      const newKPAs = [];
      kpas.forEach((kpa) => {
        newKPAs.push({
          name: kpa.name,
          objective: kpa.objective,
          kpaId: kpa.id,
          kpis: this.getCriteriaData(kpa.kpi),
          weight: kpa.weight,
          outcome: this.getOutcome(kpa.id),
          ratingId: this.getRatingId(kpa.id),
          managerRating: null,
          managerScore: this.getManagerScore(kpa.id, kpa),
        });
      });

      return newKPAs;
    },

    getOutcome(kpasid) {
      let outcome;
      this.orgAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          outcome = appraisal.outcome;
        }
        return {};
      });
      return outcome || "";
    },

    getManagerScore(kpasid) {
      let score;
      this.orgAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          score = appraisal.managerScore;
        }
        return {};
      });
      return null || score;
    },

    getRatingId(kpasid) {
      let ratePoint;
      let rating;

      this.orgAppraisals.filter((appraisal) => {
        if (appraisal.id === kpasid && appraisal.cycle === this.activeTab) {
          if (this.finalAppraisal) {
            ratePoint = appraisal.managerRatingPoint;
          } else {
            ratePoint = appraisal.flagId;
          }
        }
        return {};
      });

      if (this.finalAppraisal) {
        this.ratingOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      } else {
        this.flagOptions.filter((ratings) => {
          if (ratings.name === ratePoint) {
            rating = ratings.id;
          }
          return {};
        });
      }
      return rating || "";
    },

    getAppraisalFlags() {
      this.$_getAppraisalFlag().then((response) => {
        const { flags } = response.data;
        flags.forEach((flag) => {
          this.flagOptions.push({
            id: flag.id,
            name: flag.name,
          });
        });
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAppraisals",
        "viewOrganizationAppraisal"
      );
      this.hasModuleAccess = true;
      this.getCycleTabs();
      this.$_getPerfomanceRating().then((response) => {
        this.Ratings = response.data.PerformanceRatings;

        response.data.PerformanceRatings.map((ratings) => {
          this.ratingOptions.push({
            id: ratings.id,
            name: ratings.rating,
          });
          return {};
        });
      });
      this.getAppraisalFlags();
    } catch (error) {
      this.hasModuleAccess = false;
      this.isLoading = false;
    }
  },
};
</script>

<style>
.label .text-razzmataz {
  display: none;
}
.fit-content {
  width: fit-content;
}

.input-select:disabled {
  background: #eeeeee;
  color: #878e99;
  border: none;
  background-image: none;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 12px;
}

.input-select {
  border: 1px solid #878e99;
  height: 38px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}
.input-select:focus {
  outline: none;
}
.input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(/img/down-arrow.f3bc3f05.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 14px;
  padding: 3px 15px;
  width: 100%;
  padding-right: 29px;
  outline: none;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  /* width: 100%; */
  /* width: fit-content; */
}
.label label {
  display: none;
}
</style>
